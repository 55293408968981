<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept type="search" label="작업부서" name="dept2" v-model="searchParam.dept2" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
          <q-btn-group flat class="col-3">
            <q-btn color="grey-8" class="calMoveBtn" outline icon="arrow_back_ios" label="이전달" @click="onPrev" />
            <q-btn color="grey-8" class="calMoveBtn" outline :label="title" style="width: 200px" @click="onToday"/>
            <q-btn color="grey-8" class="calMoveBtn" outline icon-right="arrow_forward_ios" label="다음달" @click="onNext" />
          </q-btn-group>
        </div>
      </template>
    </c-search-box>
    <q-calendar
      ref="calendar"
      v-model="selectedDate"
      view="month"
      locale="ko-kr"
      :day-height="140"
      @change="onChange"
      show-work-weeks
    >
      <template #week="{ week }">
        <template v-for="(computedEvent, index) in getWeekEvents(week)">
          <q-badge
            :key="index"
            class="q-row-event"
            :class="badgeClasses(computedEvent, 'day')"
            :style="badgeStyles(computedEvent, 'day', week.length)"
            @click="openJobDetail"
          >
            <template v-if="computedEvent.event">
              <q-icon :name="computedEvent.event.icon" class="q-mr-xs"></q-icon>
              <span class="ellipsis">{{ computedEvent.event.title }}</span>
            </template>
          </q-badge>
        </template>
      </template>
    </q-calendar>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
import QCalendarm from '@quasar/quasar-ui-qcalendar'
import {QCalendar} from '@quasar/quasar-ui-qcalendar'
import "@quasar/quasar-ui-qcalendar/dist/index.css"

const CURRENT_DAY = new Date()

function getCurrentDay (day) {
  const newDay = new Date(CURRENT_DAY)
  newDay.setDate(day)
  const tm = QCalendarm.parseDate(newDay)
  return tm.date
}

export default {
  name: 'outernal-manage',
  components: {
    QCalendar,
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        year: '',
        dept1: '',
        dept2: '',
      },
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        width: '80%',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      listUrl: '',
      title: '',
      dateFormatter: undefined,
      start: undefined,
      selectedDate: '',
      events: [
        {
          title: '설비#001 예방점검',
          color: 'orange',
          start: getCurrentDay(3),
          end: getCurrentDay(3)
        },
        {
          title: '설비#01-005 예방점검',
          color: 'green',
          start: getCurrentDay(7),
          end: getCurrentDay(7),
          icon: 'cake'
        },
        {
          title: '설비#08-002 예방점검',
          color: 'red',
          start: getCurrentDay(8),
          end: getCurrentDay(9),
          icon: 'group'
        },
        {
          title: '설비#12-002 예방점검',
          color: 'teal',
          start: getCurrentDay(8),
          end: getCurrentDay(8),
          icon: 'free_breakfast'
        },
        {
          title: '설비#11-015 예방점검',
          color: 'blue-grey',
          start: getCurrentDay(16),
          end: getCurrentDay(16),
          icon: 'card_giftcard'
        },
        {
          title: '설비#08-105 예방점검',
          color: 'blue',
          start: getCurrentDay(18),
          end: getCurrentDay(18),
          icon: 'ondemand_video'
        },
        {
          title: '설비#01-025 예방점검',
          color: 'teal',
          start: getCurrentDay(18),
          end: getCurrentDay(18),
          icon: 'fastfood'
        },
        {
          title: '설비#03-035 예방점검',
          color: 'teal',
          start: getCurrentDay(21),
          end: getCurrentDay(21),
          icon: 'fastfood'
        },
        {
          title: '설비#01-006 예방점검',
          color: 'blue',
          start: getCurrentDay(23),
          end: getCurrentDay(23),
          icon: 'fastfood'
        },
        {
          title: '설비#07-021 예방점검',
          color: 'orange',
          start: getCurrentDay(23),
          end: getCurrentDay(24),
          icon: 'group'
        },
      ]
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
    },
    onChange ({ start }) {
      this.start = start
      this.updateTitle()
    },
    onPrev () {
      this.$refs.calendar.prev()
    },
    onNext () {
      this.$refs.calendar.next()
    },
    onToday() {
      this.$refs.calendar.moveToToday()
    },
    updateTitle () {
      this.title = this.start.year + '년 ' + this.start.month + '월'
    },
    isCssColor (color) {
      return !!color && !!color.match(/^(#|(rgb|hsl)a?\()/)
    },

    badgeClasses (infoEvent, type) {
      const color = infoEvent.event !== undefined ? infoEvent.event.color : 'transparent'
      const cssColor = this.isCssColor(color)
      const isHeader = type === 'header'

      return {
        [`text-white bg-${color}`]: !cssColor,
        'full-width': !isHeader && (!infoEvent.side || infoEvent.side === 'full'),
        'left-side': !isHeader && infoEvent.side === 'left',
        'right-side': !isHeader && infoEvent.side === 'right',
        'cursor-pointer': infoEvent.event !== undefined,
        'q-day-event-void': infoEvent.event === undefined // height: 0, padding: 0
      }
    },

    badgeStyles (infoEvent, type, weekLength, timeStartPos, timeDurationHeight) {
      const s = {}
      if (timeStartPos) {
        s.top = timeStartPos(infoEvent.event.time) + 'px'
      }
      if (timeDurationHeight) {
        s.height = timeDurationHeight(infoEvent.event.duration) + 'px'
      }
      if (infoEvent.size !== undefined) {
        s.width = ((100 / weekLength) * infoEvent.size) + '% !important'
      }
      // s['align-items'] = 'flex-start'
      return s
    },

    getWeekEvents (week) {
      // console.log('weekdays',weekdays)
      const tsFirstDay = QCalendarm.parsed(week[0].date + ' 00:00')
      const tsLastDay = QCalendarm.parsed(week[week.length - 1].date + ' 23:59')
      const firstDay = QCalendarm.getDayIdentifier(tsFirstDay)
      const lastDay = QCalendarm.getDayIdentifier(tsLastDay)

      const eventsWeek = []
      this.events.forEach((event, id) => {
        const tsStartDate = QCalendarm.parsed(event.start + ' 00:00')
        const tsEndDate = QCalendarm.parsed(event.end + ' 23:59')
        const startDate = QCalendarm.getDayIdentifier(tsStartDate)
        const endDate = QCalendarm.getDayIdentifier(tsEndDate)

        if (this.isBetweenDatesWeek(startDate, endDate, firstDay, lastDay)) {
          const left = QCalendarm.daysBetween(tsFirstDay, tsStartDate, true)
          const right = QCalendarm.daysBetween(tsEndDate, tsLastDay, true)

          eventsWeek.push({
            id, // index event
            left, // Position initial day [0-6]
            right, // Number days available
            size: week.length - (left + right), // Size current event (in days)
            event // Info
          })
        }
      })

      const events = []
      if (eventsWeek.length > 0) {
        const infoWeek = eventsWeek.sort((a, b) => a.left - b.left)
        infoWeek.forEach((event, i) => {
          this.insertEvent(events, week.length, infoWeek, i, 0, 0)
        })
      }

      return events
    },

    insertEvent (events, weekLength, infoWeek, index, availableDays, level) {
      const iEvent = infoWeek[index]
      if (iEvent !== undefined && iEvent.left >= availableDays) {
        // If you have space available, more events are placed
        if (iEvent.left - availableDays) {
          // It is filled with empty events
          events.push({ size: iEvent.left - availableDays })
        }
        // The event is built
        events.push({ size: iEvent.size, event: iEvent.event })

        if (level !== 0) {
          // If it goes into recursion, then the item is deleted
          infoWeek.splice(index, 1)
        }

        const currentAvailableDays = iEvent.left + iEvent.size

        if (currentAvailableDays < weekLength) {
          const indexNextEvent = QCalendarm.indexOf(infoWeek, e => e.id !== iEvent.id && e.left >= currentAvailableDays)

          this.insertEvent(
            events,
            weekLength,
            infoWeek,
            indexNextEvent !== -1 ? indexNextEvent : index,
            currentAvailableDays,
            level + 1
          )
        } // else: There are no more days available, end of iteration
      }
      else {
        events.push({ size: weekLength - availableDays })
        // end of iteration
      }
    },

    isBetweenDates (date, start, end) {
      return date >= start && date <= end
    },

    isBetweenDatesWeek (dateStart, dateEnd, weekStart, weekEnd) {
      return (
        (dateEnd < weekEnd && dateEnd >= weekStart) ||
          dateEnd === weekEnd ||
          (dateEnd > weekEnd && dateStart <= weekEnd)
      )
    },
    openJobDetail() {
      this.popupOptions.param = {
        dailyCheckId: 'DPI0000011',
      };
      this.popupOptions.title = '예방점검 상세'; 
      this.popupOptions.target = () => import(`${"./preventiveCheckDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
